@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

form {
    justify-content: center;
    font-family: "Lato", sans-serif;
}

.item {
    width: 100%;
    margin: 20px;
}
.div2 {
    width: 95%;
    display: flex;
    justify-content: center;
}
.div_teste {
    justify-content: center;
}
input {
    width: 100%;
    height: 50px;
    text-indent: 10px;
    font-family: "Lato", sans-serif;
}

select {
    width: 100%;
    height: 50px;
    text-indent: 10px;
}
button {
    width: 100%;
    height: 50px;
}

p {
    width: auto;
    height: 30px;
    font-family: "Lato", sans-serif;
    text-align: justify;
    margin-bottom: 5px;
}