.main__container {
    height: 100% !important;
    font-family: 'Courier New', Courier, monospace;
}

.ck-editor {
    height: 100% !important;
}

.ck-toolbar button {
    margin-top: 25px !important;
}

.ck-editor__main {
    height: 85% !important;
}

.ck-content {
    height: 100% !important;
}

ul {
    list-style-type: circle !important;
	margin:10px;
  }

li {
    list-style-type: circle !important;
    display: list-item !important;	
	margin:10px;
  }

  .table table {
      width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    min-width: 400px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
  }

  .responderDIV {
      width: 100% !important;
      display: flex;
      justify-content: flex-end;
  }
  .paragrafo {
      height: auto !important;
  }
  
  .table table thead tr {
    background-image: linear-gradient(rgb(41, 100, 144), rgb(23, 155, 207));
    color: #ffffff;
    text-align: left;
    font-weight: bold;
  }
  
  .table table th, td {
    padding: 12px 15px;
  }
  
  .preview table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .table table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  
  .table table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
  }
  
  .table table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
  }

