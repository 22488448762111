.geral {
    background-color: #238A85;
    font-family: 'Lato', sans-serif;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.verde {
    color: #238A85;
    font-weight: 400;
    font-size: 20px;
}
.caixa {
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 10px;
    width: 50%;
    height: 50%;
}
.right {
    padding: 20px;
}
.titulo {
    font-weight: 700;
    font-size: 20px;
}
button {
    width: 49%;
    padding: 5px;
    border: 0px;
    color: white;
    background-color: #4158d0;
    font-weight: 700;
}
input {
    width: 80%;
    height: 30px;
}
.subtitulo {
    width: 80%;
}
.caixa div {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

body {
    margin: 0;
}