@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,700;0,900;1,300&display=swap');

.flexNav {
    display: flex;
    flex-direction: row;
    width: 50%;
}
.imgLogoNav {
    height: 100% !important;
    padding: 0;
}
.direita {
    justify-content: flex-end;
}
.esquerda {
    justify-content: flex-start;
}

.homeNavbar {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #238A85;
    margin-bottom: -0.1em;
}

.menuItemNav {
    text-align: center;
    font: 18px Lato !important;
    font-family: 'Lato', sans-serif !important;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 20px;
    font-weight: 700 !important;
    align-self: center;
    height: 63.8px;
    text-decoration: none;
}

.navTitle {
    font-size: 18px !important;
    text-decoration: none;
}
.loginNav {
    border-color: white;
    border-style: solid !important;
    border-width: 2px;
    border-radius: 30px;
    width: 20%;
    min-width: 90px;
    margin-right: 0.5em;
}

.aNav:hover {
    color:#4158d0
}