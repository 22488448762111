.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

html {
  scroll-behavior: smooth;
}

.my-float {
  margin-top: 16px;
}

.bbutton {
  font-size: 1.4em;
  font-weight: bold;
  justify-content: center;
  padding: 0.5em;
  min-width: 5em;
  width: 30%;
  margin-left: 1em;
  border-radius: 0.8em;
}

.contactButton {
  font-size: 20px;
  font-weight: bold;
  padding: 20px;
  min-width: 90px;
  width: 200px;
  border-radius: 10px;
  padding: 20px;
  margin-right: 1em;
  text-decoration: none;
}

.contato {
  display: flex;
}

.depoimentos {
  display: grid;
  color: black !important;
  margin-bottom: 1em;
}

/* Desktop */
@media (min-width: 768px) {
  .bannerButtons {
    display: flex;
    padding: 40px;
    width: 40%;
    min-width: 200px;
    justify-content: space-evenly;
  }
  .bannerMobile {
    display: none;
  }

  .parceirosDesktop{
    width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .parceirosMobile{
    display: none;
  }
}

.certificacao{
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Mobile */
@media (max-width: 768px) {
  body {
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }
  .bannerButtons {
    display: flex;
    padding: 40px;
    height: fit-content;
    justify-content: space-evenly;
  }
  .carousel .control-dots {
    margin-bottom: 0;
  }
  .bannerDesktop {
    display: none;
  }
  .contato {
    display: grid;
  }
  .contactButton {
    margin: 0.5em;
  }
  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 0.4em;
    right: 0.6em;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }

  .parceirosDesktop{
    display: none;
  }

  .parceirosMobile{
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .certificacao{
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
