.flexContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100% !important;
    justify-content: center;
    padding: 20px;
}

.title {
    font-size: 36px;
}

.customText {
    font-size: 24px !important;
}

.boxContent {
    min-width: 300px;
    padding: 20px;
}
.imgContent {
    max-width: 300px !important;
}

.boxContent p {
    height: auto !important;
}