.item_lista {
    display: flex;
    height: 200px;
    width: 100%;
    margin-top: 30px;
    background-color: white;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.conteudo_pequeno {
    margin: 20px;
    margin-top: 80px;
    top: 20%;
    width: 35%;
    height: 100%;
    
    justify-content: center;
    align-items: center;
    align-content: center;
}

.subconteudo {
    height: 40%;
}

button {
    bottom: 10px;
    background: linear-gradient(90deg, rgb(41, 100, 144), rgb(23, 155, 207));
    color: white;
}

textarea {
    width: 98%;
    height: 80%;
    margin-top: 22px;
}
.conteudo_grande {
    width: 65%;
    height: 100%;

    justify-content: center;
    align-items: center;
    align-content: center;
}